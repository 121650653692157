import Swiper from 'swiper/bundle';

let swiperBottom = new Swiper('.bottom-slider', {
    direction: 'horizontal',
    loop: true,
    autoplay: {
        delay: 5000,
    },
    breakpoints: {
        0: {
          slidesPerView: 1,
        },
        600: {
            slidesPerView: 2,
        },
        750: {
            slidesPerView: 3,
        },
        1023: {
          slidesPerView: 4,
        },
        1400: {
          slidesPerView: 5,
        }
    }
})
