import Swiper from 'swiper/bundle';

let swiper = new Swiper('.slider__main', {
    direction: 'horizontal',
    loop: true,
    autoplay: {
        delay: 5000,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
            index += 1;
            return '<span class="' + className + '">' + index + '</span>';
        },
    },
})
