import GLightbox from 'glightbox'

const references = document.querySelector('.references__main-case');

if (references) {
    const buttonOpenGallery = document.querySelectorAll('.references__link');

    buttonOpenGallery.forEach(el=> {
        el.addEventListener('click', ()=> {
            el.parentNode.children[0].click();
        })
    })

    const lightbox = GLightbox();
}