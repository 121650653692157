window.addEventListener('load', ()=> {
    const topNavMainMenu = document.querySelector('.header__navigation ul:not(.sub-menu)');
    const menuBars = document.querySelector('.bars');
    const mobileMenu = ()=> {
        menuBars.addEventListener('click', ()=> {
            if(topNavMainMenu.classList.contains('menuExpanded')){
                topNavMainMenu.style.right = `9999px`;
                topNavMainMenu.classList.remove('menuExpanded');
            }else {
                topNavMainMenu.style.right = `0`;
                topNavMainMenu.classList.add('menuExpanded');
            }
        })
    }
    if(window.innerWidth > 1023) {
        const slideLine = document.querySelector('.header__currentpage');
        const topNavElements = document.querySelectorAll('.header__navigation ul:not(.sub-menu) li');
        const topNav = document.querySelector('.header__navigation');
        const currentPage = document.querySelector('.current_page_item');
        const subMenu = document.querySelector('.sub-menu');
        let currentLeftPosition, currentWidt;

        subMenu.addEventListener('mouseenter', ()=> {
            slideLine.style.display = 'none'
        })
        subMenu.addEventListener('mouseleave', ()=> {
            slideLine.style.display = 'block'
        })

        const startPosition = ()=> {
            slideLine.style.left = `${currentPage.offsetLeft}px`;
            slideLine.style.width = `${currentPage.offsetWidth}px`;
        }

        startPosition();

        topNavElements.forEach(el=> {
            el.addEventListener('mouseenter', ()=> {
                currentLeftPosition = el.offsetLeft;
                currentWidt = el.offsetWidth;
                slideLine.style.left = `${currentLeftPosition}px`;
                slideLine.style.width = `${currentWidt}px`;
            })
        })

        topNav.addEventListener('mouseleave', ()=> {
            startPosition();
        })
    }else {
        mobileMenu();
    }
    window.addEventListener('resize', ()=> {
        if(window.innerWidth < 1024) {
            mobileMenu();
        }
    })
})