import {gsap, ScrollTrigger} from 'gsap/all'

gsap.registerPlugin(ScrollTrigger);

const homepageBoxes = document.querySelector('.offer__main-right-boxes');

if(homepageBoxes) {
    gsap.fromTo(homepageBoxes, {opacity: 0}, {opacity: 1, 
        scrollTrigger: {
            trigger: homepageBoxes,
            start: 'top 60%',
    }})
}

const offerMainLeft = document.querySelector('.offer__main-left');

if(offerMainLeft) {
    gsap.fromTo(offerMainLeft, {opacity: 0, x: '-=100'}, {opacity: 1, x: 0, 
        scrollTrigger: {
            trigger: offerMainLeft,
            start: 'top 60%',
    }})
}

const aboutUs = document.querySelector('.about-us');

if(aboutUs) {
    gsap.fromTo(aboutUs.children, {y: '+=100', opacity: 0}, {y: 0, opacity: 1, stagger: 0.2, duration: 1, ease: 'easeInOut', scrollTrigger: {
        trigger: aboutUs,
        start: 'top 60%',
    }});  
}

const sliderLogos = document.querySelector('.slider-logos');

if(sliderLogos) {
    gsap.fromTo(sliderLogos, {opacity: 0}, {opacity: 1, 
        scrollTrigger: {
            trigger: sliderLogos,
            start: 'top 60%',
    }})
}

const newsesMain = document.querySelector('.newses');

if(newsesMain) {
    gsap.fromTo(newsesMain, {opacity: 0, x: '-=200'}, {opacity: 1, x: 0,
        scrollTrigger: {
            trigger: newsesMain,
            start: 'top 60%',
    }})
}

const footer = document.querySelector('.footer');

if(footer) {
    gsap.fromTo(footer.children, {y: '+=100', opacity: 0}, {y: 0, opacity: 1, stagger: 0.2, duration: 1, ease: 'easeInOut', scrollTrigger: {
        trigger: footer,
        start: 'top 60%',
    }});
}

const subpageSubheader = document.querySelector('.subpage__subheader');

if(subpageSubheader) {
    gsap.fromTo(subpageSubheader, {y: '+=100', opacity: 0}, {y: 0, opacity: 1, stagger: 0.2, duration: 1, ease: 'easeInOut'});
}

const subpageShowbar = document.querySelector('.subpage-showbar');

if(subpageShowbar) {
    gsap.fromTo(subpageShowbar.children, {y: '+=100', opacity: 0}, {y: 0, opacity: 1, stagger: 0.2, duration: 1, ease: 'easeInOut', scrollTrigger: {
        trigger: subpageShowbar,
        start: 'top 60%',
    }});
}

const references = document.querySelector('.references');

if(references) {
    gsap.fromTo(references.children, {y: '+=100', opacity: 0}, {y: 0, opacity: 1, stagger: 0.2, duration: 1, ease: 'easeInOut', scrollTrigger: {
        trigger: subpageShowbar,
        start: 'top 60%',
    }});
}

const newsesMainSub = document.querySelector('.newses-case');

if(newsesMainSub) {
    gsap.fromTo(newsesMainSub, {opacity: 0, x: '-=200'}, {opacity: 1, x: 0,
        scrollTrigger: {
            trigger: newsesMainSub,
            start: 'top 60%',
    }})
}

const bottomBoxes = document.querySelector('.bottom-boxes');

if(bottomBoxes) {
    gsap.fromTo(bottomBoxes, {opacity: 0, x: '-=200'}, {opacity: 1, x: 0,
        scrollTrigger: {
            trigger: bottomBoxes,
            start: 'top 60%',
    }})
}

const questionBar = document.querySelector('.question-bar');

if(questionBar) {
    gsap.fromTo(questionBar.children, {y: '+=100', opacity: 0}, {y: 0, opacity: 1, stagger: 0.2, duration: 1, ease: 'easeInOut', scrollTrigger: {
        trigger: questionBar,
        start: 'top 60%',
    }});
}

const realizations = document.querySelector('.realizations');

if(realizations) {
    gsap.fromTo(realizations.children, {y: '+=100', opacity: 0}, {y: 0, opacity: 1, stagger: 0.2, duration: 1, ease: 'easeInOut', scrollTrigger: {
        trigger: realizations,
        start: 'top 60%',
    }});
}

const download = document.querySelector('.download');

if(download) {
    gsap.fromTo(download, {opacity: 0, x: '-=200'}, {opacity: 1, x: 0,
        scrollTrigger: {
            trigger: download,
            start: 'top 60%',
    }})
}

const contact = document.querySelector('.contact__main');

if(contact) {
    gsap.fromTo(contact.children, {y: '+=100', opacity: 0}, {y: 0, opacity: 1, stagger: 0.2, duration: 1, ease: 'easeInOut', scrollTrigger: {
        trigger: contact,
        start: 'top 60%',
    }});
}

const form = document.querySelector('.form');

if(form) {
    gsap.fromTo(form, {opacity: 0, x: '-=200'}, {opacity: 1, x: 0,
        scrollTrigger: {
            trigger: form,
            start: 'top 60%',
    }})
}